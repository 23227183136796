<template>
  <div>
    <div id="iphone">
      <div id="screen">
        <div id="notch" style="background-color: #000">
          <div style="margin-left: -40px; font-size: 12px; padding-top: 5px">
            <p style="color: #000; margin-right: 10em">Dtac</p>
          </div>
          <div id="speaker" style="margin-top: -40px"></div>
        </div>
        <div id="stat-bar" style="margin-top: -5px">
          <feather-icon
            icon="WifiIcon"
            style="color: #000"
            class="mr-50"
            size="15"
          />
          <feather-icon
            icon="BatteryChargingIcon"
            style="color: #000"
            class="mr-90"
            size="15"
          />
        </div>
        <div class="header_link">
          <img :src="require('@/assets/Icon.png')" />
          &nbsp;&nbsp;hotspot.socioWifi.co.th
        </div>
        <div class="header_link">hotsport</div>
        <div style="height: 60px" class="header_wifi">
          <div>
            <feather-icon
              icon="ChevronLeftIcon"
              style="color: #bebdc2"
              class="mr-90"
              size="28"
            />
            <feather-icon
              icon="ChevronRightIcon"
              style="color: #bebdc2"
              class="mr-90"
              size="28"
            />
          </div>
          <h4><b>เข้าสู่ระบบ</b></h4>
          <h4 style="color: #1d7bf7">ยกเลิก&nbsp;&nbsp;</h4>
        </div>
        <hr />
        <div class="body-captive" id="body-captive">
          <div class="header">
            <span class="logo2" style="margin-top: -0.7em">
             
            </span>
            <span class=""> Free Wireless Internet </span>
          </div>
          <div class="body-data">
            <div  id="img_background"></div>
            <video
              style="display: none"
              id="video"
              src=""
              muted
              autoplay
              loop
            ></video>
            
            <div v-if="detail" id="detail">
              {{ detail }}
            </div>
            <div class="box">
              <br />

              <div align="center">
                <div id="btn_login_line">
                  <div @click="login()">
                    <div class="line-text">
                      <b-avatar
                        size="40"
                        :src="require('@/assets/images/LINE_Brand_icon.png')"
                      />
                      <span style="font-size: 16px; color: #fff">
                       ดำเนินหารเชื่อมต่อด้วย LINE</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
    <div align="center" style="margin-top: 1em"></div>
  </div>
</template>

<script>
import { BAvatar } from "bootstrap-vue";

export default {
  components: { BAvatar },
  data() {
    return {
      logo: "https://api.socio.co.th/images/transparent.png",
      vdo: "",
      detail: "",
    };
  },
  created() {},
  mounted() {},
  methods: {
    set_detail(text) {
      this.detail = text;
    },
    set_Image(img) {
      document.getElementById("video").style.display = "none";
      document.getElementById("img_background").style.display = "";
      this.logo = img;
      console.log("img :>> ", img);
      document.getElementById("img_background").style.backgroundImage =
        "url(" + img + ")";
    },
    set_Video(url) {
      this.vdo = url;
      document.getElementById("img_background").style.display = "none";
      document.getElementById("video").style.display = "";
      this.logo = null;
      var video = (document.getElementById("video").src = url);
      video.load();
      video.play();
    },
  },
};
</script>

<style lang="scss" scoped>
.line-text {
  margin-left: -15px;
}
#img_background {
  width: 380px;
  height: 380px;
  background-image: url("https://api.socio.co.th/images/transparent.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  //border-bottom-right-radius: 30px;
  //border-bottom-left-radius: 30px;
}
video {
  width: 380px;
  height: 380px;
  //object-fit: cover;
  background-color: rgba(0, 0, 0, 0.747);
}
#detail {
  margin-top:5px;
  background-color: rgba(255, 255, 255, 0.605);
  width: 378px;
  padding: 10px;
  color: rgb(0, 0, 0);
  border-radius: 10px;
  text-align: center;
}
.body-captive {
  background-color: rgba(0, 0, 0, 0.423);
  // background-image: url("https://static.socifi.com/27/7832/26581/68832/angry-birds-guide-wallpaper-2.jpg");
  height: 627px;
  margin-top: -10px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
}
.header {
  font-size: 12px;
  font-weight: 700;
  //position: fixed;
  z-index: 1011;
  overflow: hidden;
  //width: 378px !important;
  text-align: center;
  color: #000000;
  line-height: 40px;
  background-color: rgba(255, 255, 255, 0.8);
}
.header > .logo2 {
  position: absolute;
  width: 81px;
  text-indent: none !important;
}

.header > .logo2 {
  margin-left: -10em;
  height: 60px;
  left: 1;
}
.brand-logo {
  // border-radius: 50%;
  width: 200px;
  margin-top: 30px;
}
.name {
  font-size: 50px;
  text-indent: -0.03em;
  color: #fff;
  text-shadow: 1px 1px 4px rgb(0 0 0 / 85%);
  margin: 15px 0;
  display: block;
  font-weight: 400;
  font-size: 24px;
}

.box {
  position: relative;
  max-width: 600px;
  margin-top: 20px;
  margin: 20px;
  padding: 20px;
  text-align: center;
  color: #fff;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flexbox;
  bottom: 0;
}

.header_wifi {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header_link {
  display: flex;
  justify-content: center;
  align-items: center;
}

#gesture-bar {
  background: rgb(144, 144, 144);
  width: 30%;
  height: 5px;
  border-radius: 5px;
  margin-left: 35%;
  display: flex;
  align-items: center;
  transition: all 1s;

  &:hover {
    cursor: pointer;
    background: black;
  }
}

#container {
  display: flex;
}

#settings {
  padding: 16px;
  border-radius: 5px;
  width: 30%;

  p {
    font-size: 12px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.54);
  }

  .round-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px;
    margin-bottom: 16px;
  }

  .round {
    width: 36px;
    height: 36px;
    border-radius: 5px;
    margin-right: 16px;
    margin-bottom: 16px;

    &:hover {
      cursor: pointer;
    }
  }
}

#iphone {
  $body-color: #000;
  margin-left: -20px;
  font-family: Arial;
  margin: auto;
  width: 410px;
  height: 812px;
  background: $body-color;
  border-radius: 50px;
  box-shadow: inset 0px 2px 8px rgba(0, 0, 0, 0.9);
  padding: 16px;

  #screen {
    // background: linear-gradient(180deg, #07acbf, #34fdd3);
    background: #fff;
    color: #000;
    height: 100%;
    border-radius: 40px;
    box-shadow: 0px 0px 2px 7px rgba(255, 255, 255, 0.05);

    #stat-bar {
      display: flex;
      align-items: center;
      position: relative;
      left: 80%;
      top: -12px;
      img {
        width: 14px;
        height: 14px;
        margin-right: 4px;
      }

      img:nth-child(1) {
        width: 18px;
        height: 8px;
      }

      img:nth-child(3) {
        width: 20px;
      }
    }

    #notch {
      background: $body-color;
      height: 25px;
      width: 55.7%;
      margin: auto;
      border-radius: 0px 0px 20px 20px;

      #speaker {
        width: 22%;
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.4),
          rgba(255, 255, 255, 0.4)
        );
        height: 4px;
        border-radius: 5px;
        margin: auto;
        position: relative;
        top: 6px;
      }
    }

    #content {
      color: #000;
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin-top: 20px;
      }
      #lock-icon {
        width: 30px;
        margin-top: 24px;
      }

      #time {
        color: white;
        font-size: 70px;
        margin-top: 8px;
      }

      #date {
        color: white;
        font-size: 16px;
      }
    }

    #actions {
      width: 85%;
      display: flex;
      justify-content: space-between;
      position: relative;
      top: 64%;
      margin: auto;

      .action-bg {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 1s;

        &:hover {
          cursor: pointer;
          background: rgba(0, 0, 0, 0.4);
        }

        img {
          width: 24px;
        }
      }

      .action-bg:nth-child(1) {
        img {
          transform: rotateZ(-45deg);
        }
      }
    }
  }
}

@media screen and (max-width: 567px) {
  #container {
    flex-direction: column;
  }

  #settings {
    width: 100%;
  }
}
</style>
